import { useCallback, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { INPUT_TYPE } from '@components/BindTypeInputs/ValueInputFactory'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { AccordionConfigExamples, BtnCopyConfigExample } from '@gantt/components/ConfigExamples'
import {
  AvailableVariables,
  BACKGROUND_BINDING_TYPE,
  BORDERS_WIDTH_BINDING_TYPE,
  GANTT_BIND_TYPE_LIST,
  LINES_WIDTH_BINDING_TYPE,
  PageContext,
  SegmentFieldType,
  TITLE_BINDING_TYPE,
  TOOLTIP_BINDING_TYPE,
} from '@gantt/components/GanttCreateOrEdit'
import { BOOLEAN_SELECT_OPTIONS, tooltipAvailableKeys } from '@gantt/constants'
import { checkValidGanttJS, getBindTypeOptionsByEnum } from '@gantt/helpers'
import { checkValidGanttJSON } from '@gantt/helpers/checkValidGanttJSON'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

import { VariablesList } from '../VariablesList'

type BaseFormFieldsProps = {
  disabled?: boolean
  isShowScriptDialog?: boolean
  isShownCornersInput?: boolean
  mainPath?: string
  watchedObject?: AutocompleteOption<string> | null
  toggleOpenScriptValueDialog: () => void
  currentBarKey?: string
  keyValue?: string
  variablesForJS?: AvailableVariables
  existingPaths?: string[]
  hiddenFields?: Map<string, boolean>
}

const colorHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.backgroundColorScriptHint'),
  static: i18next.t('ganttCreate.timelineForm.hint.backgroundColorStaticHint'),
}

const tooltipHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
}

const tooltipPlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.tooltipJS'),
}

const titleHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.titleScriptHint'),
}

const titlePlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.titleJS'),
}

export const BaseFormFields = ({
  disabled = false,
  isShowScriptDialog = false,
  isShownCornersInput = false,
  mainPath,
  watchedObject,
  toggleOpenScriptValueDialog,
  keyValue,
  currentBarKey,
  variablesForJS,
  existingPaths,
  hiddenFields,
}: BaseFormFieldsProps) => {
  const { watch } = useFormContext()
  const { availableVariables } = useContext(PageContext)
  const { t } = useTranslation()
  const variables = variablesForJS || availableVariables

  const booleanSelectOptions = Object.values(BOOLEAN_SELECT_OPTIONS).map(item => ({
    id: item.id,
    name: item.name,
    value: item.value,
  }))

  const isShown = (key: string) => !hiddenFields?.get(getPath(key))

  const getPath = (path: string) => {
    if (mainPath) {
      return `${mainPath}.${path}`
    }

    return `${path}`
  }

  const watchedTitleBindType = watch(getPath('title'))?.bindType
  const watchedTooltipBindType = watch(getPath('tooltip'))?.bindType
  const watchedColorBindType = watch(getPath('background'))?.bindType

  const getPrefix = (type: SegmentFieldType) => {
    return [currentBarKey, keyValue, type].filter(s => !!s).join('_')
  }

  const funValidator = (
    value: string,
    returnValueType: GANTT_SCRIPT_RETURN_TYPE,
    keys?: string[],
    bindType?: GANTT_BIND_TYPE_LIST.JS | GANTT_BIND_TYPE_LIST.JSON
  ) => {
    if (bindType === GANTT_BIND_TYPE_LIST.JSON) {
      return checkValidGanttJSON(value, returnValueType, keys)
    }

    return checkValidGanttJS(value, variables, returnValueType, keys)
  }

  const funValidatorString = useCallback((value: string) => {
    return funValidator(value, GANTT_SCRIPT_RETURN_TYPE.STRING)
  }, [])

  const funValidatorBoolean = useCallback((value: string) => {
    return funValidator(value, GANTT_SCRIPT_RETURN_TYPE.BOOLEAN)
  }, [])

  const funValidatorTooltip = useCallback(
    (value: string) => {
      return funValidator(
        value,
        GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS,
        tooltipAvailableKeys,
        watchedTooltipBindType
      )
    },
    [watchedTooltipBindType]
  )

  const fieldPicker = useMemo(() => {
    return {
      existingPaths,
    }
  }, [existingPaths])

  return (
    <>
      {isShown('title') && (
        <>
          <BindTypeInputs
            isFieldsetMarkup
            bindTypeOptions={getBindTypeOptionsByEnum(TITLE_BINDING_TYPE)}
            containerName={getPath('title')}
            fieldPicker={fieldPicker}
            fieldsetTitle={t('ganttCreate.timelineForm.generalTitle')}
            hintDict={titleHints}
            isDisabled={disabled}
            object={watchedObject}
            placeholderDict={titlePlaceholders}
            prefix={getPrefix(SegmentFieldType.TITLE)}
            scriptEditor={{
              validator: funValidatorString,
            }}
          />
          {[TITLE_BINDING_TYPE.JS].includes(watchedTitleBindType) && (
            <AccordionConfigExamples>
              <VariablesList variables={variables} />
            </AccordionConfigExamples>
          )}
        </>
      )}
      {isShown('tooltip') && (
        <>
          <BindTypeInputs
            isFieldsetMarkup
            bindTypeOptions={getBindTypeOptionsByEnum(TOOLTIP_BINDING_TYPE)}
            containerName={getPath('tooltip')}
            fieldPicker={fieldPicker}
            fieldsetTitle={t('ganttCreate.timelinesObjectTooltips')}
            hintDict={tooltipHints}
            isDisabled={disabled}
            object={watchedObject}
            placeholderDict={tooltipPlaceholders}
            prefix={getPrefix(SegmentFieldType.TOOLTIP)}
            scriptEditor={{
              validator: funValidatorTooltip,
            }}
          />
          {[TOOLTIP_BINDING_TYPE.JS, TOOLTIP_BINDING_TYPE.JSON].includes(
            watchedTooltipBindType
          ) && (
            <AccordionConfigExamples>
              <VariablesList variables={variables} />
            </AccordionConfigExamples>
          )}
          <Box alignItems='center' display='flex' mb={1}>
            <Box pr={1}>{t('ganttCreate.configExamples.blockTitle')}</Box>
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.simpleTooltip')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.groupTooltip')} />
          </Box>
        </>
      )}
      {isShown('background') && (
        <>
          <BindTypeInputs
            isFieldsetMarkup
            bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
            containerName={getPath('background')}
            fieldsetTitle={t('ganttCreate.timelineForm.background')}
            hintDict={colorHints}
            inputType={INPUT_TYPE.COLOR}
            object={watchedObject}
            scriptEditor={{
              validator: funValidatorString,
            }}
          />
          {[BACKGROUND_BINDING_TYPE.JS].includes(watchedColorBindType) && (
            <AccordionConfigExamples>
              <VariablesList variables={variables} />
            </AccordionConfigExamples>
          )}
        </>
      )}
      {isShown('boxVerticalGuideLines') && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.boxVerticalGuideLines.blockLabel')}</legend>
          {isShown('boxVerticalGuideLines.has') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              containerName={getPath('boxVerticalGuideLines.has')}
              inputType={INPUT_TYPE.SELECT}
              valueOptions={booleanSelectOptions}
              blockLabel={t(
                'ganttCreate.timelineForm.boxVerticalGuideLines.hasBoxVerticalGuideLines'
              )}
              scriptEditor={{
                validator: funValidatorBoolean,
              }}
            />
          )}
          {isShown('boxVerticalGuideLines.isBoxColor') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BORDERS_WIDTH_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.boxVerticalGuideLines.isBoxColor')}
              containerName={getPath('boxVerticalGuideLines.isBoxColor')}
              inputType={INPUT_TYPE.BOOLEAN}
              scriptEditor={{
                validator: funValidatorBoolean,
              }}
            />
          )}
          {isShown('boxVerticalGuideLines.color') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.boxVerticalGuideLines.color')}
              containerName={getPath('boxVerticalGuideLines.color')}
              hintDict={colorHints}
              inputType={INPUT_TYPE.COLOR}
              object={watchedObject}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
          {isShown('boxVerticalGuideLines.width') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BORDERS_WIDTH_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.boxVerticalGuideLines.width')}
              containerName={getPath('boxVerticalGuideLines.width')}
              fieldPicker={fieldPicker}
              inputType={INPUT_TYPE.NUMBER}
              isDisabled={disabled}
              object={watchedObject}
              placeholderDict={tooltipPlaceholders}
              numberInput={{
                max: 5,
              }}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
          {isShown('boxVerticalGuideLines.type') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.boxVerticalGuideLines.type')}
              containerName={getPath('boxVerticalGuideLines.type')}
              // hintDict={colorHints}
              object={watchedObject}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
        </Box>
      )}
      {isShown('borders.inner') && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.innerBorders.blockLabel')}</legend>
          {isShown('borders.inner.has') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.innerBorders.hasInnerBorders')}
              containerName={getPath('borders.inner.has')}
              inputType={INPUT_TYPE.SELECT}
              valueOptions={booleanSelectOptions}
              scriptEditor={{
                validator: funValidatorBoolean,
              }}
            />
          )}
          {isShown('borders.inner.color') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.innerBorders.color')}
              containerName={getPath('borders.inner.color')}
              hintDict={colorHints}
              inputType={INPUT_TYPE.COLOR}
              object={watchedObject}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
          {isShown('borders.inner.width') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BORDERS_WIDTH_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.innerBorders.width')}
              containerName={getPath('borders.inner.width')}
              fieldPicker={fieldPicker}
              inputType={INPUT_TYPE.NUMBER}
              isDisabled={disabled}
              object={watchedObject}
              placeholderDict={tooltipPlaceholders}
              numberInput={{
                max: 5,
              }}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
        </Box>
      )}
      {isShown('borders.outer') && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.outerBorders.blockLabel')}</legend>
          {isShown('borders.outer.has') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.outerBorders.hasOuterBorders')}
              containerName={getPath('borders.outer.has')}
              inputType={INPUT_TYPE.SELECT}
              valueOptions={booleanSelectOptions}
              scriptEditor={{
                validator: funValidatorBoolean,
              }}
            />
          )}
          {isShown('borders.outer.color') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.outerBorders.color')}
              containerName={getPath('borders.outer.color')}
              hintDict={colorHints}
              inputType={INPUT_TYPE.COLOR}
              object={watchedObject}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
          {isShown('borders.outer.width') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BORDERS_WIDTH_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.outerBorders.width')}
              containerName={getPath('borders.outer.width')}
              fieldPicker={fieldPicker}
              inputType={INPUT_TYPE.NUMBER}
              isDisabled={disabled}
              object={watchedObject}
              placeholderDict={tooltipPlaceholders}
              numberInput={{
                max: 5,
              }}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
        </Box>
      )}
      {isShown('lines.top') && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.linesTop.blockLabel')}</legend>
          {isShown('lines.top.has') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.linesTop.hasLinesTop')}
              containerName={getPath('lines.top.has')}
              inputType={INPUT_TYPE.SELECT}
              valueOptions={booleanSelectOptions}
              scriptEditor={{
                validator: funValidatorBoolean,
              }}
            />
          )}
          {isShown('lines.top.color') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.linesTop.color')}
              containerName={getPath('lines.top.color')}
              hintDict={colorHints}
              inputType={INPUT_TYPE.COLOR}
              object={watchedObject}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
          {isShown('lines.top.width') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(LINES_WIDTH_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.linesTop.width')}
              containerName={getPath('lines.top.width')}
              fieldPicker={fieldPicker}
              inputType={INPUT_TYPE.NUMBER}
              isDisabled={disabled}
              object={watchedObject}
              placeholderDict={tooltipPlaceholders}
              numberInput={{
                max: 5,
              }}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
        </Box>
      )}
      {isShown('lines.bottom') && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.linesBottom.blockLabel')}</legend>
          {isShown('lines.bottom.has') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.linesBottom.hasLinesBottom')}
              containerName={getPath('lines.bottom.has')}
              inputType={INPUT_TYPE.SELECT}
              valueOptions={booleanSelectOptions}
              scriptEditor={{
                validator: funValidatorBoolean,
              }}
            />
          )}
          {isShown('lines.bottom.color') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.linesBottom.color')}
              containerName={getPath('lines.bottom.color')}
              hintDict={colorHints}
              inputType={INPUT_TYPE.COLOR}
              object={watchedObject}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
          {isShown('lines.bottom.width') && (
            <BindTypeInputs
              bindTypeOptions={getBindTypeOptionsByEnum(LINES_WIDTH_BINDING_TYPE)}
              blockLabel={t('ganttCreate.timelineForm.linesBottom.width')}
              containerName={getPath('lines.bottom.width')}
              fieldPicker={fieldPicker}
              inputType={INPUT_TYPE.NUMBER}
              isDisabled={disabled}
              object={watchedObject}
              placeholderDict={tooltipPlaceholders}
              numberInput={{
                max: 5,
              }}
              scriptEditor={{
                validator: funValidatorString,
              }}
            />
          )}
        </Box>
      )}
      {isShown('textColor') && (
        <BindTypeInputs
          isFieldsetMarkup
          bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
          containerName={getPath('textColor')}
          fieldsetTitle={t('ganttCreate.timelineForm.textColor')}
          hintDict={colorHints}
          inputType={INPUT_TYPE.COLOR}
          object={watchedObject}
          scriptEditor={{
            validator: funValidatorString,
          }}
        />
      )}
      {isShown('parameters') && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.parameters')}</legend>
          {isShown('hasCorners') && (
            <FormInput
              inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
              label={t('ganttCreate.timelineForm.hasCorners')}
              labelPlacement='end'
              name={getPath('hasCorners')}
            />
          )}
        </Box>
      )}
    </>
  )
}
