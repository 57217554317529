import { FC } from 'react'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@microservices/wiskey-react-table-component'

type CustomToolbarComponentProps = {
  showColumnsButton: boolean
  isAssistantSearch: boolean
}

export const CustomToolbarComponent: FC<CustomToolbarComponentProps> = ({
  showColumnsButton,
  isAssistantSearch,
}) => {
  return (
    <GridToolbarContainer>
      {showColumnsButton && !isAssistantSearch && <GridToolbarColumnsButton title='title' />}
    </GridToolbarContainer>
  )
}
