import { GETAllTooltips, GETObjectDataParams } from '@types'

import { commonAPI } from './common.api'

export const tooltipAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllTooltips: build.query<GETAllTooltips, GETObjectDataParams | void>({
      query: params => ({
        url: 'ui/tooltip',
        params: {
          searchPattern: params?.searchPattern,
        },
      }),
      providesTags: ['AllTooltips'],
    }),
    fetchTooltipById: build.query<any, number>({
      query: id => ({
        url: `ui/tooltip/id/${id}`,
      }),
      providesTags: ['SingleTooltip'],
    }),
    createTooltip: build.mutation<any, any>({
      query: tooltip => ({
        url: '/ui/tooltip',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['AllTooltips'],
    }),
    updateTooltip: build.mutation<any, any>({
      query: tooltip => ({
        url: '/ui/tooltip',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['AllTooltips', 'SingleTooltip'],
    }),
    copyTooltip: build.mutation<any, any>({
      query: tooltip => ({
        url: 'ui/tooltip/copy',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['AllTooltips'],
    }),
    deleteTooltip: build.mutation<any, number>({
      query: id => ({
        url: `/ui/tooltip/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllTooltips'],
    }),
    updateTooltipJSON: build.mutation<any, any>({
      query: tooltip => ({
        url: '/ui/tooltip/json',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['SingleTooltip'],
    }),
  }),
})

export const {
  useFetchAllTooltipsQuery,
  useFetchTooltipByIdQuery,
  useCreateTooltipMutation,
  useUpdateTooltipMutation,
  useCopyTooltipMutation,
  useDeleteTooltipMutation,
  useUpdateTooltipJSONMutation,
} = tooltipAPI
