import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MuiEvent } from '@microservices/wiskey-react-table-component'

import {
  ConfiguredEntity,
  ConfiguredEntityProps,
  GetConfiguredEntityParamsHandle,
} from '@pages/ConfiguredEntity'
import { SearchAssistant } from '@pages/ConfiguredEntity/components/SearchAssistant'
import { OpenFormProps } from '@pages/ConfiguredEntity/types'

import { useFetchSearchAssistantByContextIdQuery } from '@redux/api'

import { getLocalStorageContext, isNotEmpty } from '@helpers'
import { CLICK_EVENT_TYPE, ENTITY } from '@constants'
import { EntityType, POSTObjectDataFilter } from '@types'

type SearchAssistantPageProps = {
  isSearchAssistantDialogWindow?: boolean
  isViewDialogWindow?: boolean
  entityId?: number
  entityCode?: string
  dialogId?: string
  handleOpenForm: (
    formProps: OpenFormProps,
    event: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => void
  path: string
  title: string
  type: ENTITY
  windowHeight?: number
  setSearchFilter?: (body: POSTObjectDataFilter) => void
  searchFilterBody?: POSTObjectDataFilter
} & Omit<ConfiguredEntityProps, 'entity' | 'entityId' | 'entityCode' | 'onSetEntity'>

export const ConfiguredEntityWithSearchAssistant: FC<SearchAssistantPageProps> = ({
  dialogId,
  handleOpenForm,
  path,
  title,
  type,
  windowHeight,
  isSearchAssistantDialogWindow,
  isViewDialogWindow,
  setSearchFilter,
  searchFilterBody,
  ...props
}) => {
  const { pathname } = useLocation()

  const isFormOpen = pathname.includes('/forms/')

  const [entity, setEntity] = useState<EntityType | null>(null)
  const [entityCode, setEntityCode] = useState<string | null>(null)
  const [entityId, setEntityId] = useState<string | number | null>(null)

  const isViewPage = !isViewDialogWindow
  const showAssistantViewPage = !isFormOpen && entity
  const showAssistantViewDialogWindow = entity || isSearchAssistantDialogWindow
  const showAssistant = isViewPage ? showAssistantViewPage : showAssistantViewDialogWindow

  const ref = useRef<GetConfiguredEntityParamsHandle>(null)

  const handleSetEntityCode = (value: string | null) => setEntityCode(value)
  const handleSetEntityId = (value: string | number | null) => setEntityId(value)
  const handleSetEntity = (value: EntityType | null) => setEntity(value)

  const handleSearchFilter = (body: POSTObjectDataFilter) => {
    if (ref.current) {
      ref.current.resetObjectData(body)

      setSearchFilter?.(body)
    }
  }

  const context = getLocalStorageContext()

  const { data: contextSA } = useFetchSearchAssistantByContextIdQuery(
    { contextId: context?.id },
    { skip: !context }
  )

  useEffect(() => {
    if (isNotEmpty(searchFilterBody)) {
      handleSearchFilter(searchFilterBody!)
    }
  }, [searchFilterBody])

  return (
    <>
      {showAssistant && (
        <SearchAssistant
          dialogId={dialogId}
          entity={entity}
          isSearchAssistant={isSearchAssistantDialogWindow}
          isSearchAssistantDialogWindow={true} //выставлен true для стилизации рабочей области как в окнах
          searchAssistantId={contextSA?.id}
          onClear={() => handleSearchFilter([])}
          onSearchFilter={handleSearchFilter}
          onSetEntityCode={handleSetEntityCode}
          onSetEntityId={handleSetEntityId}
          // для второй VIEW в одной рабочей области
          searchFilterBody={searchFilterBody}
        />
      )}
      <ConfiguredEntity
        key={entityId}
        ref={ref}
        dialogId={dialogId}
        entity={entity}
        handleOpenForm={handleOpenForm}
        isSearchAssistantDialogWindow={isSearchAssistantDialogWindow}
        path={path}
        title={title}
        type={ENTITY.VIEW}
        windowHeight={windowHeight}
        onSetEntity={handleSetEntity}
        {...props}
        entityCode={props.entityCode || entityCode}
        entityId={props.entityId || entityId}
        isViewDialogWindow={true} //выставлен true для стилизации рабочей области как в окнах
      />
    </>
  )
}
