import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'

import { useFetchAllObjectQuery } from '@redux/api'
import { useFetchTooltipByIdQuery } from '@redux/api/tooltips.api'

import { ROUTES } from '@constants'

import { TooltipForm } from '../types'

import { useHandlers } from './useHandlers'

const defaultValues = {
  id: null,
  code: undefined,
  title: undefined,
  object: null,
}

export const useTooltipCreateOrEdit = () => {
  const location = useLocation() as unknown as Location & { state?: { id?: string } }
  const { id } = useParams()
  const copyId = location.state?.id
  const methods = useForm<TooltipForm>({ defaultValues })
  const {
    reset,
    formState: { isDirty },
  } = methods

  const modelType = ROUTES.CONFIG_TOOLTIPS_CREATE === location.pathname ? 'create' : 'edit'
  const isEdit = id || copyId

  const { data: objects, isLoading: isLoadingObjects } = useFetchAllObjectQuery()
  const { data: tooltipDataById, isFetching: isLoadingTooltipById } = useFetchTooltipByIdQuery(
    Number(id),
    {
      skip: !isEdit,
      refetchOnMountOrArgChange: true,
    }
  )

  const { state, data, handlers } = useHandlers({ id, methods })

  useEffect(() => {
    if (tooltipDataById) {
      reset({
        id: tooltipDataById.id,
        code: tooltipDataById.code,
        title: tooltipDataById.title,
        object: {
          id: tooltipDataById.objectCode,
          label: tooltipDataById.objectTitle,
        },
      })
    }
  }, [tooltipDataById])

  return {
    data: {
      objects,
      isLoadingObjects,
      isLoadingTooltipById,
      modelType,
      methods,
      copyId,
      id,
      isDirty,
      isEdit,
      tooltipDataById,
      ...data,
    },
    state: {
      ...state,
    },
    handlers: {
      ...handlers,
    },
  }
}
