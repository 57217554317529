import { CutRestrictionDTO } from 'src/types'
import { GridPinnedColumns } from '@microservices/wiskey-react-table-component'

export const transformFixRestrictions = (
  pinnedColumnsRestrictions: CutRestrictionDTO[]
): GridPinnedColumns => {
  return pinnedColumnsRestrictions?.reduce(
    (prev: GridPinnedColumns, current: CutRestrictionDTO) => {
      if (current.value === 'right' || current.value === 'left') {
        prev[current.value]?.push(current.fieldCode)
      }

      return prev
    },
    { right: [], left: [] }
  )
}
