import { KeyboardEvent, useContext } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { GridColumns } from '@microservices/wiskey-react-table-component'
import { Grid } from '@mui/material'

import { PageContext } from '@pages/Contexts'
import { TableWithActions } from '@pages/Contexts/components/TableWithActions'

import { CONTEXTS_COLUMNS, GENERATOR_INPUT_TYPE } from '@constants'

type Allcontext = {
  methods: UseFormReturn<any>
}

export const AllContexts = ({ methods }: Allcontext) => {
  const { t } = useTranslation()

  const {
    contextTables,
    contextIsLoading,
    onShowCopyDialog,
    onCreateContext,
    onEditContext,
    onDeleteContext,
    onKeyDown,
  } = useContext(PageContext)

  return (
    <>
      <Grid container item alignItems='center' flexWrap='nowrap' gap={1} p={1}>
        <Grid item>
          <Button sx={{ mb: 1 }} variant='contained' onClick={onCreateContext}>
            {t(`contexts.addButton.context`)}
          </Button>
        </Grid>
        <Grid item flexGrow={1}>
          <FormProvider {...methods}>
            <form>
              <Grid item sm={12} xs={12}>
                <FormInput
                  inputType={GENERATOR_INPUT_TYPE.INPUT}
                  name='searchPattern'
                  placeholder={t(`contexts.placeholder.search`)}
                  size='small'
                  // eslint-disable-next-line
                  // @ts-ignore
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onKeyDown(e)}
                />
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <TableWithActions
        columns={CONTEXTS_COLUMNS as GridColumns<any>}
        loading={contextIsLoading}
        rows={contextTables}
        sx={{ px: 1 }}
        onCopy={onShowCopyDialog}
        onDelete={onDeleteContext}
        onEdit={onEditContext}
      />
    </>
  )
}
