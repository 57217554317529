import arrowRightIcon from '@assets/images/arrow-right.svg'
import { GridEnrichedColDef } from '@microservices/wiskey-react-table-component'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'

export const generateDataGridLinkColumn = (isDialogWindow: boolean): GridEnrichedColDef => {
  return {
    field: ' ',
    type: 'linkToForm',
    align: 'center',
    minWidth: 25,
    width: isDialogWindow ? 25 : 50,
    sortable: false,
    resizable: false,
    hideable: false,
    disableColumnMenu: true,
    disableReorder: true,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    pinnedColumn: PINNED_COLUMN.LEFT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    renderCell: params => (
      <CustomSvgIcon
        key={params.id}
        fontSize={isDialogWindow ? 'small' : 'medium'}
        src={arrowRightIcon}
        sx={{ display: 'flex' }}
      />
    ),
  } as GridEnrichedColDef
}
