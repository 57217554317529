import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { DataSource } from '@components/DataSource'
import { GeneralButtons } from '@components/GeneralButtons'
import { NewModel } from '@components/NewModel'

import { TooltipContent } from './components'
import { useTooltipCreateOrEdit } from './hooks'

export const TooltipCreateOrEdit: FC = () => {
  const { t } = useTranslation()
  const {
    state: { currentTab },
    data: {
      modelType,
      methods,
      copyId,
      id,
      isDirty,
      objects,
      isLoadingObjects,
      isLoadingTooltipById,
    },
    handlers: { handleSave, handleCancel, handleTabChange },
  } = useTooltipCreateOrEdit()

  return (
    <Box>
      <FormProvider {...methods}>
        <Box px={1} py={0}>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item my={1}>
              <Typography variant='h5'>{t(`tooltips.title.${modelType}`)}</Typography>
            </Grid>
          </Grid>
          <NewModel loading={isLoadingTooltipById} />
          <DataSource
            isDisabledObject={Boolean(!id || !copyId)}
            isLoading={isLoadingObjects}
            objects={objects}
          />
        </Box>
      </FormProvider>
      <Grid container justifyContent='flex-end'>
        <GeneralButtons
          cancelText={t('viewOrFormCreate.cancelBtnText')}
          saveDisabled={!isDirty}
          saveText={t('viewOrFormCreate.saveBtnText')}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      </Grid>

      <PageContentLayout key={currentTab} sx={{ pt: 1, px: 1 }}>
        <TooltipContent />
      </PageContentLayout>
    </Box>
  )
}
