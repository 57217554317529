export const ROUTES = {
  CHOOSE_CONTEXT: '/choose_context',
  CONFIG_CONTEXTS: '/contexts',
  CONFIG_CONTEXTS_CREATE: '/contexts/create',
  CONFIG_CONTEXTS_EDIT: '/contexts/edit',
  CONFIG_CONTEXTS_EDIT_ID: '/contexts/edit/:id',
  CONFIG_VIEWS: '/views',
  DEFAULT_PAGE: '/',
  FORM: '/forms/:formCode',
  FORMS: '/forms',
  CONFIG_FORMS: '/forms',
  CONFIG_VIEWS_CREATE: '/views/create',
  CONFIG_VIEWS_GROUPS: '/views/groups',
  CONFIG_FORMS_CREATE: '/forms/create',
  CONFIG_VIEWS_EDIT_CODE: '/views/edit/:code',
  CONFIG_VIEWS_EDIT: '/views/edit',
  CONFIG_FORMS_EDIT: '/forms/edit',
  CONFIG_CONTEXT_MENU: '/context_menu',
  CONFIG_CONTEXT_MENU_CREATE: '/context_menu/create',
  CONFIG_CONTEXT_MENU_EDIT: '/context_menu/edit',
  CONFIG_CONTEXT_MENU_EDIT_ID: '/context_menu/edit/:id',
  CONFIG_FORMS_EDIT_CODE: '/forms/edit/:code',
  CONFIG_VIEWS_TABS_CODE_CREATE: '/views/edit/tabs/:code',
  CONFIG_VIEWS_TABS_CREATE: '/views/edit/tabs',
  CONFIG_CONTROL_PANELS: '/control_panels',
  CONFIG_CONTROL_PANELS_CREATE: '/control_panels/create',
  CONFIG_CONTROL_PANELS_EDIT: '/control_panels/edit',
  CONFIG_CONTROL_PANELS_EDIT_ID: '/control_panels/edit/:id',
  DICTIONARY: '/dictionary',
  MENU_SETTING: '/menu_setting',
  LOGIN: '/login',
  SEARCH_ASSISTANT: '/search_assistant',
  SEARCH: 'search',
  PARAMETERS: '/parameters',
  CONFIG_DISPLAY_RULES: '/display_rules',
  CONFIG_DISPLAY_RULES_CREATE: '/display_rules/create',
  CONFIG_DISPLAY_RULES_EDIT: '/display_rules/edit',
  CONFIG_DISPLAY_RULES_EDIT_ID: '/display_rules/edit/:id',
  CONFIG_GANTT: '/gantt',
  CONFIG_GANTT_CREATE: '/gantt/create',
  CONFIG_GANTT_EDIT: '/gantt/edit',
  CONFIG_GANTT_EDIT_CODE: '/gantt/edit/:code',
  CONFIG_LIST_CONTROLS: '/list_controls',
  CONFIG_LIST_CONTROLS_GROUPS: '/list_controls/groups',
  CONFIG_LIST_CONTROLS_CREATE: '/list_controls/create',
  CONFIG_LIST_CONTROLS_EDIT: '/list_controls/edit',
  CONFIG_LIST_CONTROLS_EDIT_CODE: '/list_controls/edit/:code',
  CONFIG_DROP_DOWN_ENTITY: '/dropdown_entities',
  CONFIG_DROP_DOWN_ENTITY_CREATE: '/dropdown_entities/create',
  CONFIG_DROP_DOWN_ENTITY_EDIT: '/dropdown_entities/edit',
  CONFIG_DROP_DOWN_ENTITY_EDIT_CODE: '/dropdown_entities/edit/:code',
  VARIABLES: '/variables',
  VARIABLES_CREATE: '/variables/create',
  VARIABLES_EDIT: '/variables/edit',
  VARIABLES_EDIT_ID: '/variables/edit/:id',
  CONFIG_STYLE_SETTINGS: '/style_settings',
  CONFIG_STYLE_SETTINGS_CREATE: '/style_settings/create',
  CONFIG_STYLE_SETTINGS_EDIT: '/style_settings/edit/',
  CONFIG_STYLE_SETTINGS_EDIT_ID: '/style_settings/edit/:id',
  CONFIG_TOOLTIPS: '/tooltips',
  CONFIG_TOOLTIPS_CREATE: '/tooltips/create',
  CONFIG_TOOLTIPS_EDIT: '/tooltips/edit',
  CONFIG_TOOLTIPS_EDIT_ID: '/tooltips/edit/:id',
}
