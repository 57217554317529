import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  useCreateTooltipMutation,
  useUpdateTooltipJSONMutation,
  useUpdateTooltipMutation,
} from '@redux/api/tooltips.api'

import { ROUTES } from '@constants'

import { TooltipForm } from '../types'

type useHandlersParams = {
  id: string | number | undefined
  methods: UseFormReturn<TooltipForm>
}

export const useHandlers = ({ id, methods }: useHandlersParams) => {
  const navigate = useNavigate()

  const { handleSubmit } = methods
  const [currentTab, setCurrentTab] = useState(0)

  const [createTooltip] = useCreateTooltipMutation()
  const [updateTooltip] = useUpdateTooltipMutation()
  const [updateTooltipJson] = useUpdateTooltipJSONMutation()

  const handleSave = handleSubmit((tooltipData: TooltipForm) => {
    const data = {
      id: tooltipData.id ?? null,
      code: tooltipData.code,
      title: tooltipData.title,
      objectCode: tooltipData.object?.id,
      objectTitle: tooltipData.object?.label,
    }

    if (tooltipData.id) {
      updateTooltip(data)
    } else {
      createTooltip(data)
        .unwrap()
        .then(res => navigate(`${ROUTES.CONFIG_TOOLTIPS_EDIT}/${res.id}`))
    }
  })

  const handleCancel = () => {
    navigate(`${ROUTES.CONFIG_TOOLTIPS}`)
  }

  const handleTabChange = (event: React.SyntheticEvent, value: number) => setCurrentTab(value)

  const handleChangeJsonMode = (value: boolean) => updateTooltipJson({ id, isJson: value })

  const handleChangeValueScriptJson = (value: string) =>
    updateTooltipJson({ id, isJson: true, jsonValue: value })

  return {
    data: {},
    state: {
      currentTab,
    },
    handlers: {
      handleSave,
      handleCancel,
      handleTabChange,
      handleChangeJsonMode,
      handleChangeValueScriptJson,
    },
  }
}
