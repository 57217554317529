import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@microservices/wiskey-react-components'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridSortModel,
} from '@microservices/wiskey-react-table-component'
import { Grid } from '@mui/material'

import { generateDataGridActionsColumnForStyleSettings } from '@pages/ConfigStyleSettings/helpers'

type TableWithActionsProps = {
  columns: GridColumns<any>
  rows: readonly any[]
  onDelete?: (id: number) => void
  onEdit?: (id: number) => void
  loading?: boolean
  currentSort: GridSortModel | undefined
  onSetSorting: (value: GridSortModel | null) => void
}

export const TableWithActions: FC<TableWithActionsProps> = ({
  columns,
  rows,
  onDelete,
  onEdit,
  loading,
  currentSort,
  onSetSorting,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number>()
  const { t } = useTranslation()

  const handleEdit = (params: GridCellParams<any>) => {
    onEdit?.(params.row.id)
  }

  const handleClickDelete = (params: GridCellParams<any>) => {
    if (params.row.id) {
      setShowDeleteModal(true)
      setCurrentRowId(params.row.id)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleClose = () => setShowDeleteModal(false)

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      generateDataGridActionsColumnForStyleSettings({
        onEdit: onEdit ? handleEdit : undefined,
        onDelete: onDelete ? handleClickDelete : undefined,
      }),
    ],
    [columns]
  )

  const handleChangeModelSort = (value: GridSortModel | null) => {
    if (value?.length) {
      onSetSorting(value)

      return
    }
    onSetSorting(null)
  }

  return (
    <Grid container sx={{ px: 1 }}>
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid container item>
        <DataGrid
          hideFooter
          columns={columnsWithActions}
          loading={loading}
          rows={rows}
          sortModel={currentSort}
          sx={{ borderRadius: 0 }}
          onSortModelChange={handleChangeModelSort}
        />
      </Grid>
    </Grid>
  )
}
