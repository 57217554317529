import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { ScriptValueDialog } from '@components/ScriptValueDialog'
import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { GENERATOR_INPUT_TYPE, MAX_INPUT_LENGTH } from '@constants'

import { useTooltipCreateOrEdit } from '../hooks/useTooltipCreateOrEdit'
import { TooltipContentForm } from '../types'

export const TooltipContent: FC = () => {
  const { t } = useTranslation()
  const methods = useForm<TooltipContentForm>({
    defaultValues: {
      isJson: false,
      jsonValue: undefined,
    },
  })
  const { watch, reset } = methods
  const watchedIsJson = watch('isJson')
  const watchedJsonValue = watch('jsonValue')

  const [isShowScriptValueDialogJson, setShowScriptValueDialogJson] = useState(false)

  const { state, data, handlers } = useTooltipCreateOrEdit()
  const { currentTab } = state
  const { id, tooltipDataById } = data
  const { handleTabChange, handleChangeJsonMode, handleChangeValueScriptJson } = handlers

  const disabledTab = !id || watchedIsJson

  useEffect(() => {
    if (tooltipDataById?.isJson) {
      reset({
        isJson: tooltipDataById.isJson,
        jsonValue: tooltipDataById.jsonValue,
      })
    }
  }, [tooltipDataById])

  return (
    <FormProvider {...methods}>
      {isShowScriptValueDialogJson && (
        <ScriptValueDialog
          windowBoundaries
          isShow={isShowScriptValueDialogJson}
          language='json'
          title={t('tooltips.title.editJson')}
          value={watchedJsonValue || ''}
          onClose={() => setShowScriptValueDialogJson(false)}
          onSave={handleChangeValueScriptJson}
        />
      )}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab disabled={disabledTab} label={t('tooltips.tabs.tooltipPoints')} />
          <Tab disabled={disabledTab} label={t('tooltips.tabs.config')} />
          <Tab disabled={disabledTab} label={t('tooltips.tabs.variables')} />
        </Tabs>
        <FormInput
          disabled={!id}
          formInputContainerSx={{ mb: 0 }}
          formLabelSx={{ minWidth: 90, width: 95 }}
          inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
          label={t('label.json')}
          labelPlacement='end'
          labelSx={{ minWidth: 60, width: 60 }}
          name='isJson'
          onChangeCheckbox={handleChangeJsonMode}
        />
      </Box>
      {watchedIsJson ? (
        <Box>
          <FormInput
            formLabelSx={{ my: 2 }}
            inputType={GENERATOR_INPUT_TYPE.TEXTAREA}
            maxLengthInput={MAX_INPUT_LENGTH}
            name='jsonValue'
            placeholder={t('contextMenuCreate.addMenuPointModal.titleJsValue.placeholder')}
            readOnly={true}
            rules={{ maxLength: MAX_INPUT_LENGTH }}
            additionalBtn={{
              isEnabled: true,
              text: 'edit',
              color: 'primary',
              variant: 'contained',
              onClick: () => setShowScriptValueDialogJson(true),
            }}
          />
        </Box>
      ) : (
        <Box>
          <TabPanel index={0} value={currentTab}>
            <Box>menuPoints</Box>
          </TabPanel>
          <TabPanel index={1} value={currentTab}>
            <Box>config</Box>
          </TabPanel>
          <TabPanel index={2} value={currentTab}>
            <Box>variables</Box>
          </TabPanel>
        </Box>
      )}
    </FormProvider>
  )
}
