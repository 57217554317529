import { FC, useContext } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { GridColumns } from '@microservices/wiskey-react-table-component'
import { Grid } from '@mui/material'

import { PageContext } from '@pages/ConfigStyleSettings/ConfigStyleSettings'

import { GENERATOR_INPUT_TYPE, STYLE_SETTINGS_COLUMNS } from '@constants'

import { TableWithActions } from '../TableWithActions'

type AllStyleSettingsProps = {
  methods: UseFormReturn<any>
}

export const AllStyleSettings: FC<AllStyleSettingsProps> = ({ methods }) => {
  const { t } = useTranslation()

  const {
    allStyleSettings,
    currentSort,
    isLoadingStyleSettingsData,
    onCreateStyleSetting,
    onDeleteStyleSetting,
    onEditStyleSetting,
    onSetSorting,
  } = useContext(PageContext)

  return (
    <>
      <Grid container item alignItems='center' flexWrap='nowrap' gap={1} p={1}>
        <Grid item>
          <Button sx={{ mb: 1 }} variant='contained' onClick={onCreateStyleSetting}>
            {t(`styleSettings.addButton.styleSetting`)}
          </Button>
        </Grid>
        <Grid item flexGrow={1}>
          <FormProvider {...methods}>
            <form>
              <Grid item sm={12} xs={12}>
                <FormInput
                  inputType={GENERATOR_INPUT_TYPE.INPUT}
                  name='searchPattern'
                  placeholder={t(`contexts.placeholder.search`)}
                  size='small'
                />
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <TableWithActions
        columns={STYLE_SETTINGS_COLUMNS as GridColumns<any>}
        currentSort={currentSort}
        loading={isLoadingStyleSettingsData}
        rows={allStyleSettings}
        onDelete={onDeleteStyleSetting}
        onEdit={onEditStyleSetting}
        onSetSorting={onSetSorting}
      />
    </>
  )
}
