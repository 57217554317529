import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCopyTooltipMutation, useDeleteTooltipMutation } from '@redux/api/tooltips.api'

import { MODAL_TYPE, ROUTES } from '@constants'

export const useHandlers = () => {
  const navigate = useNavigate()

  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false)
  const [copyTooltipData, setCopyTooltipData] = useState<any | null>(null)

  const [copyTooltip, { isLoading: isLoadingCopyTooltip }] = useCopyTooltipMutation()
  const [deleteTooltip] = useDeleteTooltipMutation()

  const handleCreateTooltip = () => {
    navigate(MODAL_TYPE.CREATE)
  }

  const handleEditTooltip = (id: number) => {
    navigate(`${ROUTES.CONFIG_TOOLTIPS_EDIT}/${id}`)
  }

  const handleCopyTooltip = (data: any) => {
    const { title, code, copyId } = data

    copyTooltip({ title, code, copyTooltipId: copyId })
      .unwrap()
      .then(res => {
        setShowCopyDialog(false)
        navigate(`${ROUTES.CONFIG_TOOLTIPS_EDIT}/${res.id}`)
      })
  }

  const handleDeleteTooltip = (id: number) => deleteTooltip(id)

  const handleShowDialog = (data: any) => {
    setCopyTooltipData(data)
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setCopyTooltipData(null)
    setShowCopyDialog(false)
  }

  return {
    state: {
      showCopyDialog,
      copyTooltipData,
      isLoadingCopyTooltip,
    },
    handlers: {
      handleShowDialog,
      handleCloseDialog,
      handleCopyTooltip,
      handleCreateTooltip,
      handleEditTooltip,
      handleDeleteTooltip,
    },
  }
}
