export const BOOLEAN_SELECT_OPTIONS = {
  TRUE: {
    id: 'true',
    name: 'true',
    value: true,
  },
  FALSE: {
    id: 'false',
    name: 'false',
    value: false,
  },
  INHERIT: {
    id: 'inherit',
    name: 'inherit',
    value: undefined,
  },
}
